import React, { Component } from "react";
import { connect } from "react-redux";

import {
    Col, Row,
    Form,
    FormCheck,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import {
    toggleAC,
    toggleAW,
    toggleLE,
    toggleSR,
    togglePW,
    togglePS,
    toggleTV
} from "../actions/search_actions";

class VehicleOptions extends Component {
    render () {
        return <Col xs={12} md={4}>
            Options:
            <Row>
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip>
                            Air Conditioning
                        </Tooltip>
                    }
                >
                    <Col>

                        <FormCheck
                            type="switch"
                            id="ac"
                            className="custom-control-input"
                            checked={this.props.ac}
                            onChange={this.props.toggleAC}
                            label="AC"
                        />

                    </Col>
                </OverlayTrigger>
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip>
                            Alloy wheels
                        </Tooltip>
                    }
                >
                    <Col>
                        <Form.Check
                            type="switch"
                            id="aw"
                            className="custom-control-input"
                            label="AW"
                            checked={this.props.aw}
                            onChange={this.props.toggleAW}
                        />
                    </Col>
                </OverlayTrigger>
            </Row>
            <Row>
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip>
                            Leather
                        </Tooltip>
                    }
                >
                    <Col>
                        <FormCheck
                            type="switch"
                            id="le"
                            label="LE"
                            checked={this.props.le}
                            onChange={this.props.toggleLE}
                        />
                    </Col>
                </OverlayTrigger>
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip>
                            Sunroof
                        </Tooltip>
                    }
                >
                    <Col>
                        <FormCheck
                            type="switch"
                            id="sr"
                            label="SR"
                            checked={this.props.sr}
                            onChange={this.props.toggleSR}
                        />
                    </Col>
                </OverlayTrigger>
            </Row>
            <Row>
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip>
                            Power Windows
                        </Tooltip>
                    }
                >
                    <Col>
                        <FormCheck
                            type="switch"
                            id="apw"
                            label="PW"
                            checked={this.props.pw}
                            onChange={this.props.togglePW}
                        />
                    </Col>
                </OverlayTrigger>
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip>
                            Power Steering
                        </Tooltip>
                    }
                >
                    <Col>
                        <FormCheck
                            type="switch"
                            id="ps"
                            label="PS"
                            checked={this.props.ps}
                            onChange={this.props.togglePS}
                        />
                    </Col>
                </OverlayTrigger>
            </Row>
            <Row>
                <OverlayTrigger
                    placement='left'
                    overlay={
                        <Tooltip>
                            Television
                        </Tooltip>
                    }
                >
                    <Col>
                        <FormCheck
                            type="switch"
                            id="tv"
                            label="TV"
                            checked={this.props.tv}
                            onChange={this.props.toggleTV}
                        />
                    </Col>
                </OverlayTrigger>
                <Col>
                </Col>
            </Row>
        </Col>;
    }
}

const mapStateToProps = state => {
    return {
        ac: state.search.ac,
        le: state.search.le,
        pw: state.search.pw,
        tv: state.search.tv,
        aw: state.search.aw,
        sr: state.search.sr,
        ps: state.search.ps
    };
};
const mapDispatchToProps = dispatch => ({
    toggleAC: () => dispatch(toggleAC()),
    toggleAW: () => dispatch(toggleAW()),
    toggleLE: () => dispatch(toggleLE()),
    toggleSR: () => dispatch(toggleSR()),
    togglePW: () => dispatch(togglePW()),
    togglePS: () => dispatch(togglePS()),
    toggleTV: () => dispatch(toggleTV())
});

export default connect(mapStateToProps, mapDispatchToProps)(VehicleOptions);
