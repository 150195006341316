import React, { Component } from "react";
import { connect } from "react-redux";

import { setAuction } from "../actions/search_actions";

import {
    FormGroup,
    FormControl,
    FormLabel
} from "react-bootstrap";

class AuctionHouse extends Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange (e) {
        this.props.setAuction(e.target.value);
    }

    render () {
        return (
            <FormGroup controlId="formControlsSelect">
                <FormLabel>Auction House:</FormLabel>
                <FormControl onChange={this.handleChange} value={this.props.auction} />
            </FormGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        auction: state.search.auction
    };
};

const mapDispatchToProps = dispatch => ({
    setAuction: (s) => dispatch(setAuction(s))
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionHouse);
