import React, { Component } from "react";
import "../App.css";
import PropTypes from "prop-types";

import { connect } from "react-redux";

import {
    Button,
    Col,
    Row,
    Container,
    FormControl,
    FormCheck,
    Collapse,
    Accordion,
    Card,
} from "react-bootstrap";

import AvtoModels from "./avto_models";
import AvtoMakes from "./avto_makes";
import AvtoTrans from "./avto_trans";
import AuctionHouses from "./auction_houses";
import VehicleTypes from "./vehicle_types";
import { StartDate, EndDate } from "./SearchYears";
import { SavedQueryDropdown } from "../dashboard-components/savedQueries";

const spaceBottom = {"marginBottom": "10px"};

import {
    clearSelection,
    setMinGrade,
    setOdoMin,
    setOdoMax,
    setChassis,
    setEngineMin,
    setEngineMax,
    changeAuctionNumber,
    toggleGradeStar,
    toggleGrade2,
    toggleGradeThree,
    toggleGradeThreeFive,
    toggleGradeFour,
    toggleGradeFourFive,
    toggleGradeFive,
    toggleGradeR,
    setKeyword,
    setDrivetrain,
} from "../actions/search_actions";
import { OdoHelp } from "../widgets/mouseovers";
import { settings } from "../settings";

import { toggleModal } from "../actions/saved_query_actions";

class Normal extends Component {
    constructor() {
        super();
        this.handleGradeChange = this.handleGradeChange.bind(this);
        this.changeMin = this.changeMin.bind(this);
        this.changeMax = this.changeMax.bind(this);
        this.changeChassis = this.changeChassis.bind(this);
        this.changeEngineMin = this.changeEngineMin.bind(this);
        this.changeEngineMax = this.changeEngineMax.bind(this);
        this.toggleExtra = this.toggleExtra.bind(this);

        this.state = { showExtraOptions: false };
    }

    handleGradeChange(e) {
        this.props.setMinGrade(e.target.value);
    }

    changeMin(e) {
        this.props.setOdoMin(e.target.value);
    }

    changeMax(e) {
        this.props.setOdoMax(e.target.value);
    }

    changeChassis(e) {
        this.props.setChassis(e.target.value);
    }

    changeEngineMin(e) {
        this.props.setEngineMin(e.target.value);
    }

    changeEngineMax(e) {
        this.props.setEngineMax(e.target.value);
    }

    toggleExtra() {
        this.setState({
            ...this.state,
            showExtraOptions: !this.state.showExtraOptions,
        });
    }

    render() {
        const spanStyle = { display: "inline" };
        return (
            <Container className="whiteBackground dropShadow wideContainer" style={ {"paddingTop": "10px", "paddingBottom": "10px"} }>
                <Accordion defaultActiveKey="0" className="greyText">
                    <Container className="blueBorderContainer" fluid style={spaceBottom}>
                        <Accordion.Toggle
                            eventKey="0"
                            className="w-100 border-0 bg-white p-0 greyText text-left italicTitle"
                        >
                            Basic Search
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Row className="show-Container">
                                    <Col className="alignCentreCol" md={1}>
                                        Make
                                    </Col>
                                    <Col
                                        className="alignCentreCol"
                                        xs={12}
                                        md={5}
                                    >
                                        <AvtoMakes />
                                    </Col>

                                    <Col
                                        className="alignCentreCol"
                                        xs={12}
                                        md={2}
                                    >
                                        Auction Number
                                    </Col>
                                    <Col
                                        className="alignCentreCol"
                                        xs={12}
                                        md={4}
                                    >
                                        <FormControl
                                            type="text"
                                            placeholder="Auction number"
                                            onChange={
                                                this.props.changeAuctionNumber
                                            }
                                            value={this.props.auctionNumber}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="alignCentreCol" md={1}>
                                        Model
                                    </Col>
                                    <Col
                                        className="alignCentreCol"
                                        xs={12}
                                        md={5}
                                    >
                                        <AvtoModels isMultiple={false} />
                                    </Col>
                                    <Col className="alignCentreCol" md={2}>
                                        Transmission
                                    </Col>
                                    <Col className="alignCentreCol" md={4} xs={12}>
                                        <AvtoTrans />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="alignCentreCol" md={2}>
                                        Year
                                    </Col>
                                    <Col className="alignCentreCol" md={4}>
                                        <Row>
                                            <Col>
                                                <StartDate />
                                            </Col>
                                            <Col
                                                md="auto"
                                                className="text-center"
                                            >
                                                to
                                            </Col>
                                            <Col>
                                                <EndDate style={spanStyle} />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="alignCentreCol" md={2}>
                                        Drivetrain
                                    </Col>
                                    <Col className="alignCentreCol" md={4}>
                                        <FormControl
                                            as="select"
                                            onChange={this.props.setDrivetrain}
                                            value={this.props.drivetrain}
                                        >
                                            <option
                                                value={
                                                    settings.drivetrain_placeholder
                                                }
                                            >
                                                {
                                                    settings.drivetrain_placeholder
                                                }
                                            </option>
                                            <option value="2WD">2WD</option>
                                            <option value="4WD">4WD</option>
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="alignCentreCol" md={2}>
                                        Mileage
                                        <OdoHelp />
                                    </Col>
                                    <Col className="alignCentreCol" md={4}>
                                        <Row>
                                            <Col>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Minimum"
                                                    onChange={this.changeMin}
                                                    value={this.props.odo_min}
                                                />
                                            </Col>
                                            <Col md="auto">to</Col>
                                            <Col>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Maximum"
                                                    onChange={this.changeMax}
                                                    value={this.props.odo_max}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className="alignCentreCol" md={2}>
                                        Chassis code
                                    </Col>
                                    <Col className="alignCentreCol" md={4}>
                                        <FormControl
                                            type="text"
                                            placeholder="Chassis Code"
                                            onChange={this.changeChassis}
                                            value={this.props.chassis}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="alignCentreCol" md={2}>
                                        Engine Size
                                    </Col>
                                    <Col className="alignCentreCol" md={4}>
                                        <Row>
                                            <Col>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Minimum"
                                                    onChange={
                                                        this.changeEngineMin
                                                    }
                                                    value={
                                                        this.props.engine_min
                                                    }
                                                />
                                            </Col>
                                            <Col md="auto">to</Col>
                                            <Col>
                                                <FormControl
                                                    type="text"
                                                    placeholder="Maximum"
                                                    onChange={
                                                        this.changeEngineMax
                                                    }
                                                    value={
                                                        this.props.engine_max
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={2}>Keyword</Col>
                                    <Col md={4}>
                                        <FormControl
                                            type="text"
                                            placeholder="Keyword"
                                            onChange={this.props.setKeyword}
                                            value={this.props.keyword}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Container>
                    <Container className="blueBorderContainer greyText" style={spaceBottom} fluid>
                        <Row>
                            <Col
                                onClick={this.toggleExtra}
                                className="italicTitle theHand"
                            >
                                Extra options
                            </Col>
                        </Row>
                        <Collapse in={this.state.showExtraOptions}>
                            <Row>
                                <Col className="alignCentreCol" md={2}>
                                    Auction Grade
                                </Col>
                                <Col md={4}>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="***"
                                                label="***"
                                                checked={this.props.grade_star}
                                                onChange={
                                                    this.props.toggleGradeStar
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="2"
                                                label="2"
                                                checked={this.props.grade_two}
                                                onChange={
                                                    this.props.toggleGrade2
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="3"
                                                label="3"
                                                checked={this.props.grade_three}
                                                onChange={
                                                    this.props.toggleGradeThree
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="3.5"
                                                label="3.5"
                                                checked={
                                                    this.props.grade_three_five
                                                }
                                                onChange={
                                                    this.props
                                                        .toggleGradeThreeFive
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="4"
                                                label="4"
                                                checked={this.props.grade_four}
                                                onChange={
                                                    this.props.toggleGradeFour
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="4.5"
                                                label="4.5"
                                                checked={
                                                    this.props.grade_four_five
                                                }
                                                onChange={
                                                    this.props
                                                        .toggleGradeFourFive
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="5"
                                                label="5"
                                                checked={this.props.grade_five}
                                                onChange={
                                                    this.props.toggleGradeFive
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormCheck
                                                type="switch"
                                                id="R or RA"
                                                label="R or RA"
                                                checked={this.props.grade_R}
                                                onChange={
                                                    this.props.toggleGradeR
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="alignCentreCol" md={2}>
                                    Special vehicles
                                </Col>
                                <Col className="alignCentreCol" md={4}>
                                    <VehicleTypes />
                                </Col>
                            </Row>
                        </Collapse>
                    </Container>
                    <Container className="blueBorderContainer greyText" style={spaceBottom} fluid>
                        <Row>
                            <Col xs={12} md={12}>
                                <AuctionHouses />
                            </Col>
                        </Row>
                    </Container>
                    <Container className="blueBorderContainer greyText" style={spaceBottom} fluid>
                        <SavedQueryDropdown />
                    </Container>
                </Accordion>

                <Row>
                    <Col style={{ textAlign: "right" }}>
                        {this.props.guest === false && (
                            <Button onClick={this.props.toggleModal}>
                                Save query
                            </Button>
                        )}
                        &nbsp;&nbsp;
                        <Button
                            style={{
                                paddingLeft: "30px",
                                paddingRight: "30px",
                            }}
                            variant="secondary"
                            onClick={this.props.searchCallback}
                            disabled={this.props.fetching}
                        >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
Normal.propTypes = {
    searchCallback: PropTypes.func.isRequired,
};
const basicMapStateToProps = (state) => {
    return {
        fetching: state.search.fetching,
        min_grade: state.search.min_grade,
        odo_min: state.search.odo_min,
        odo_max: state.search.odo_max,
        chassis: state.search.chassis,
        engine_min: state.search.engine_min,
        engine_max: state.search.engine_max,
        auctionNumber: state.search.auctionNumber,
        drivetrain: state.search.drivetrain,
        keyword: state.search.keyword,

        guest: state.profile.guest,

        grade_star: state.search.grade_star,
        grade_two: state.search.grade_two,
        grade_three: state.search.grade_three,
        grade_three_five: state.search.grade_three_five,
        grade_four: state.search.grade_four,
        grade_four_five: state.search.grade_four_five,
        grade_five: state.search.grade_five,
        grade_R: state.search.grade_R,
    };
};

const basicMapDispatchToProps = (dispatch) => ({
    clearSelection: () => dispatch(clearSelection()),
    setMinGrade: (grade) => dispatch(setMinGrade(grade)),
    setOdoMin: (s) => dispatch(setOdoMin(s)),
    setOdoMax: (s) => dispatch(setOdoMax(s)),
    setEngineMin: (s) => dispatch(setEngineMin(s)),
    setEngineMax: (s) => dispatch(setEngineMax(s)),
    setChassis: (s) => dispatch(setChassis(s)),
    changeAuctionNumber: (s) => dispatch(changeAuctionNumber(s.target.value)),

    toggleGradeStar: () => dispatch(toggleGradeStar()),
    toggleGrade2: () => dispatch(toggleGrade2()),
    toggleGradeThree: () => dispatch(toggleGradeThree()),
    toggleGradeThreeFive: () => dispatch(toggleGradeThreeFive()),
    toggleGradeFour: () => dispatch(toggleGradeFour()),
    toggleGradeFourFive: () => dispatch(toggleGradeFourFive()),
    toggleGradeFive: () => dispatch(toggleGradeFive()),
    toggleGradeR: () => dispatch(toggleGradeR()),
    setKeyword: (ev) => dispatch(setKeyword(ev.target.value)),
    setDrivetrain: (ev) => dispatch(setDrivetrain(ev.target.value)),

    toggleModal: () => dispatch(toggleModal()),
});

export default connect(basicMapStateToProps, basicMapDispatchToProps)(Normal);
