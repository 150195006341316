import React, { Component } from "react";
import {
    Form,
    Button,
    Row, Col,
} from "react-bootstrap";

class transKeyVals extends Component {

    constructor(props) {
        super(props);
        this.focusRef = React.createRef();
        this.state = {
            newAttr: "",
            newVal: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.AddNew = this.AddNew.bind(this);
    }

    handleInputChange(e) {
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            ...this.state,
            [name]: value
        });
        this.props.currentNew(name, value);
    }



    AddNew () {
        if (this.state.newAttr !== "" && this.state.newVal !== "") {
            this.props.addNew(this.state.newAttr, this.state.newVal);
            this.setState({ newAttr: "", newVal: "" });
            this.props.currentNew("", "");
            this.focusRef.current.focus();
        }
        else {
            alert("Please enter values before adding");
        }
    }

    ignoreLeftRight(e) {
        /*
        stop propagation of left-and-right buttons
        otherwise the image gallery moves, too!
        */
        if (e.key === "ArrowRight" || e.key === "ArrowLeft")
            e.stopPropagation();

    }

    render() {
        if (this.props.type === 1) {
            return (<Form.Group as={Row} >

                <Form.Label column sm={2}>
                    {this.props.attribute}
                </Form.Label>
                <Col sm={8} size="sm">
                    <Form.Control type="textarea" onKeyDown={this.ignoreLeftRight} value={this.props.val} onChange={this.props.handleChange} />
                </Col>
                <Col sm={1}>
                    <Button variant="danger" size="sm" tabIndex="-1" onClick={this.props.handleDelete}>-</Button>

                </Col>

            </Form.Group>);
        }
        else {
            return (
                <form>
                    <Form.Group as={Row}>

                        <Col sm={2}>
                            <Form.Control type="textarea" name="newAttr" value={this.state.newAttr} ref={this.focusRef} onChange={this.handleInputChange} />
                        </Col><Col sm={8}>
                            <Form.Control type="textarea" name="newVal" value={this.state.newVal} onChange={this.handleInputChange} />
                        </Col>
                        <Col sm={1}>
                            <Button variant="success" size="sm" onClick={this.AddNew}>add</Button>
                        </Col>
                    </Form.Group>
                </form>);
        }

    }

}

export default transKeyVals;