import * as Datetime from "react-datetime";
import "../../node_modules/react-datetime/css/react-datetime.css";

import React, { Component } from "react";
import { connect } from "react-redux";

import { setStartDate, setEndDate } from "../actions/search_actions";

import {
    FormLabel
} from "react-bootstrap";
// import { settings } from '../settings';

class SearchDates extends Component {
    constructor (props) {
        super(props);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange = this.handleEndChange.bind(this);
    }

    handleStartChange (e) {
    // moment object
        this.props.setStartDate(e);
    }

    handleEndChange (e) {
        this.props.setEndDate(e);
    }

    render () {
        return (
            <div>
                <FormLabel>Auction dates:</FormLabel>
                <Datetime closeOnSelect value={this.props.start_date} onChange={this.handleStartChange} inputProps={{ placeholder: "Start date" }} dateFormat="YYYY-MM-DD" timeFormat={false} />
                <Datetime closeOnSelect value={this.props.end_date} onChange={this.handleEndChange} inputProps={{ placeholder: "End date" }} dateFormat="YYYY-MM-DD" timeFormat={false} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        start_date: state.search.start_date,
        end_date: state.search.end_date
    };
};

const mapDispatchToProps = dispatch => ({
    setStartDate: (g) => dispatch(setStartDate(g)),
    setEndDate: (g) => dispatch(setEndDate(g))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchDates);
