import React from "react";
import Datetime from "react-datetime";
import {Row, Col, InputGroup, Button} from "react-bootstrap";
import Octicon, { X } from "@githubprimer/octicons-react";

import "./DateRangePicker.css";

/**
 * Combines two date pickers to allow selecting a date range. Has props which
 * are passed through to both date pickers (inputProps, dateFormat, timeFormat),
 * or the buttons for clearing the selected date (variant).
 * The given callbacks are passed directly to the Datetime's onChange prop.
 * When a date is cleared, it runs the callback with `null` as the argument.
 * @param {Object} props
 *
 * @param {(value: moment.Moment | string | null) => void} props.onStartChanged
 * @param {(value: moment.Moment | string | null) => void} props.onEndChanged
 * @param {moment.Moment | null} [props.startValue] if a controlled component
 * @param {moment.Moment | null} [props.endValue] if a controlled component
 *
 * @param {string} [props.startPlaceholder] placeholder text for start date
 * @param {string} [props.endPlaceholder] placeholder text for end date
 * @param {string | boolean} [props.dateFormat] date format string, or `false`
 * to not include dates
 * @param {string | boolean} [props.timeFormat] time format string, or `false`
 * to not include times
 *
 * @param {string} [props.clearButtonVariant] value to pass to the `variant`
 * prop of each "clear" button (which clears the date).
 *
 * @param {Object} [props.style] style to be passed to top-level Row component
 */
export default function DateRangePicker({
    onStartChanged,
    onEndChanged,
    startValue,
    endValue,
    startPlaceholder,
    endPlaceholder,
    dateFormat,
    timeFormat,
    clearButtonVariant = "primary",
    style,
})
{
    return <Row className="date-range-picker" style={style}>
        <Col md="auto">
            From:
        </Col>
        <Col>
            <InputGroup className="date-range-picker-select">
                <Datetime
                    inputProps={{placeholder: startPlaceholder}}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    value={startValue}
                    onChange={onStartChanged}
                />
                <InputGroup.Append>
                    <Button variant={clearButtonVariant} size="sm"
                        onClick={() => onStartChanged(null)}
                    >
                        <Octicon icon={X} verticalAlign="middle"/>
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </Col>
        <Col md="auto">
            To:
        </Col>
        <Col>
            <InputGroup className="date-range-picker-select">
                <Datetime
                    inputProps={{placeholder: endPlaceholder}}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    value={endValue}
                    onChange={onEndChanged}
                />
                <InputGroup.Append>
                    <Button variant={clearButtonVariant} size="sm"
                        onClick={() => onEndChanged(null)}
                    >
                        <Octicon icon={X} verticalAlign="middle"/>
                    </Button>
                </InputGroup.Append>
            </InputGroup>
        </Col>
    </Row>;
}
