import React, { Component } from "react";
import { connect } from "react-redux";

import {
    FormGroup,
    FormControl,
    FormLabel

} from "react-bootstrap";

import { selectModels } from "../actions/search_actions";
import { settings } from "../settings";

import { translateModelF } from "../functions";

class AvtoModels extends Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.selector = null;
    }

    handleChange (e) {
    // get the selected items
        const options = e.target.options;
        const value = [];
        for (let i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        this.props.selectModels(value);
    }

    render () {
    // compile the list
        let view = null;

        if (settings.countries.indexOf(this.props.make_selected) >= 0) {
            // groupname. Lookup by key
            view = this.props.models.filter(make_model => settings.bins[this.props.make_selected].indexOf(make_model.make) >= 0);
        } else if (this.props.make_selected === settings.others) {
            // others group
            // not a known car, therefore not in the known cars list
            view = this.props.models.filter(make_model => settings.knownMakes.indexOf(make_model.make) === -1);
        } else {
            // single manufacturer
            view = this.props.models.filter(make_model => make_model.make === this.props.make_selected);
        }

        // push 'OTHER' to the bottom
        let theOther = null;
        for (let i = view.length - 1; i >= 0; i--) {
            if (view[i].model === "OTHER") {
                theOther = view[i];
                delete view[i];
            }
        }
        if (theOther !== null) { view.push(theOther); }

        // hack to get the multi-select box bigger
        // the size=15 should show 15 rows, but is not respected.
        // using CSS instead, for the time being
        return (
            <FormGroup controlId="formControlsSelectMultiple">
                <FormLabel>Models</FormLabel>
                <FormControl style={{ height: "250px" }} key="ModelSelectBox" as="select" multiple onChange={this.handleChange} value={this.props.models_selected}>
                    <option key={"placeholder"} value={settings.model_placeholder}>{settings.model_placeholder}</option>
                    {view.map(function (model) {
                        return <option key={model.model} value={model.model}>{translateModelF(model.model)}</option>;
                    })}
                </FormControl>
            </FormGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        make_selected: state.search.make_selected,
        models: state.search_history.make_model,
        models_selected: state.search.models_selected,
        lastUpdate: state.search_history.baseinfoLastUpdate
    };
};

const mapDispatchToProps = dispatch => ({
    selectModels: (models) => dispatch(selectModels(models))
});

export default connect(mapStateToProps, mapDispatchToProps)(AvtoModels);
