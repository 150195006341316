import React, { Component } from "react";
import "../App.css";
import PropTypes from "prop-types";
import { settings } from "../settings";

import { connect } from "react-redux";

import {
    Button,
    Col, Row, Container,
    FormGroup,
    FormControl,
    FormLabel,
    FormCheck

} from "react-bootstrap";

import Models from "./avto_history_models";
import Makes from "./avto_history_makes";
import { TransmissionWithLabel } from "./avto_trans";

import AvtoGears from "./avto_gears";
import SearchYears from "./SearchYears";
import SearchDates from "./search_dates";
import AuctionHouse from "./auctionHouse";
import VehicleOptions from "./vehicle_options";
import VehicleTypes from "./vehicle_types";

import {
    clearSelection,
    setMinGrade,
    setOdoMin,
    setOdoMax,
    setChassis,
    setEngineMin,
    setEngineMax,
    changeAuctionNumber,

    toggleGradeStar,
    toggleGrade2,
    toggleGradeThree,
    toggleGradeThreeFive,
    toggleGradeFour,
    toggleGradeFourFive,
    toggleGradeFive,
    toggleGradeR,
    setKeyword,
    setDrivetrain
} from "../actions/search_actions";

import { OdoHelp } from "../widgets/mouseovers";

class Advanced extends Component {
    constructor () {
        super();
        this.handleGradeChange = this.handleGradeChange.bind(this);
        this.changeMin = this.changeMin.bind(this);
        this.changeMax = this.changeMax.bind(this);
        this.changeChassis = this.changeChassis.bind(this);
        this.changeEngineMin = this.changeEngineMin.bind(this);
        this.changeEngineMax = this.changeEngineMax.bind(this);
    }

    handleGradeChange (e) {
        this.props.setMinGrade(e.target.value);
    }

    changeMin (e) {
        this.props.setOdoMin(e.target.value);
    }

    changeMax (e) {
        this.props.setOdoMax(e.target.value);
    }

    changeChassis (e) {
        this.props.setChassis(e.target.value);
    }

    changeEngineMin (e) {
        this.props.setEngineMin(e.target.value);
    }

    changeEngineMax (e) {
        this.props.setEngineMax(e.target.value);
    }

    /*
        auction houses for history is not yet supported
        issue with the dates provided
        <Row>
                <Col xs={12} md={12}>
                    <AuctionHouses />
                </Col>
        </Row>
    */

    render () {
        const spaceBottom = {"marginBottom": "10px"};
        return (
            <Container className="whiteBackground dropShadow wideContainer" style={ {"paddingTop": "10px", "paddingBottom": "10px"} }>
                <Container className="blueBorderContainer" fluid style={spaceBottom}>
                    <Row className="show-Container">
                        <Col xs={12} md={4}>
                            <Makes />
                        </Col>

                        <Col xs={12} md={4}>
                            <Models isMultiple={true} />

                        </Col>
                        <Col xs={12} md={4}>
                            <FormLabel>Auction number:</FormLabel>
                            <FormControl type="text" placeholder="Auction number" onChange={this.props.changeAuctionNumber} value={this.props.auctionNumber} />
                            <AuctionHouse />
                        </Col>
                    </Row>
                    <FormGroup as={Row} controlId="formControlsSelect">
                        <Col xs={12} md={4}>
                        </Col>
                        <Col xs={12} md={4}>
                            <FormLabel>Odometer min: <OdoHelp /></FormLabel>
                            <FormControl type="text" placeholder="Minimum" onChange={this.changeMin} value={this.props.odo_min} />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormLabel>Odometer max:</FormLabel>
                            <FormControl type="text" placeholder="Maximum" onChange={this.changeMax} value={this.props.odo_max} />
                        </Col>
                    </FormGroup>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormLabel>Chassis code starts with:</FormLabel>
                            <FormControl type="text" placeholder="Chassis Code" onChange={this.changeChassis} value={this.props.chassis} />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormLabel>Engine size minimum:</FormLabel>
                            <FormControl type="text" placeholder="Minimum" onChange={this.changeEngineMin} value={this.props.engine_min} />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormLabel>Engine size maximum:</FormLabel>
                            <FormControl type="text" placeholder="Maximum" onChange={this.changeEngineMax} value={this.props.engine_max} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4}>
                            <FormLabel>Keyword:</FormLabel>
                            <FormControl type="text" placeholder="Keyword" onChange={this.props.setKeyword} value={this.props.keyword} />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormLabel>Drivetrain:</FormLabel>
                            <FormControl as="select" onChange={this.props.setDrivetrain} value={this.props.drivetrain}>
                                <option value={settings.drivetrain_placeholder} >{settings.drivetrain_placeholder}</option>
                                <option value="2WD">2WD</option>
                                <option value="4WD">4WD</option>
                            </FormControl>
                        </Col>
                        <Col xs={12} md={4}></Col>

                    </Row>
                    <Row>
                        <Col xs={12} md={4}><TransmissionWithLabel /></Col>
                        <Col xs={12} md={4}><AvtoGears /></Col>
                        <Col xs={12} md={4}><SearchDates /></Col>

                    </Row>
                    <Row>
                        <VehicleOptions />
                        <Col xs={12} md={4}>
                            <VehicleTypes />
                        </Col>
                        <Col xs={12} md={4}>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="***"
                                        label="***"
                                        checked={this.props.grade_star}
                                        onChange={this.props.toggleGradeStar}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="2"
                                        label="2"
                                        checked={this.props.grade_two}
                                        onChange={this.props.toggleGrade2}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="3"
                                        label="3"
                                        checked={this.props.grade_three}
                                        onChange={this.props.toggleGradeThree}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="3.5"
                                        label="3.5"
                                        checked={this.props.grade_three_five}
                                        onChange={this.props.toggleGradeThreeFive}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="4"
                                        label="4"
                                        checked={this.props.grade_four}
                                        onChange={this.props.toggleGradeFour}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="4.5"
                                        label="4.5"
                                        checked={this.props.grade_four_five}
                                        onChange={this.props.toggleGradeFourFive}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="5"
                                        label="5"
                                        checked={this.props.grade_five}
                                        onChange={this.props.toggleGradeFive}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormCheck
                                        type="switch"
                                        id="R or RA"
                                        label="R or RA"
                                        checked={this.props.grade_R}
                                        onChange={this.props.toggleGradeR}
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <SearchYears />

                    <br />

                    <Row>
                        <Col style={{ textAlign: "right" }} >
                            <Button onClick={this.props.clearSelection}>
                                Clear Form
                            </Button>
                            &nbsp;&nbsp;
                            <Button onClick={this.props.searchCallback}
                                variant="secondary"
                                disabled={this.props.fetching}
                            >
                                Search
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
        );
    }
}
Advanced.propTypes = {
    searchCallback: PropTypes.func.isRequired
};
const basicMapStateToProps = state => {
    return {
        fetching: state.search.fetching,
        min_grade: state.search.min_grade,
        odo_min: state.search.odo_min,
        odo_max: state.search.doo_max,
        chassis: state.search.chassis,
        engine_min: state.search.engine_min,
        engine_max: state.search.engine_max
    };
};

const basicMapDispatchToProps = dispatch => ({
    clearSelection: () => dispatch(clearSelection()),
    setMinGrade: (grade) => dispatch(setMinGrade(grade)),
    setOdoMin: (s) => dispatch(setOdoMin(s)),
    setOdoMax: (s) => dispatch(setOdoMax(s)),
    setEngineMin: (s) => dispatch(setEngineMin(s)),
    setEngineMax: (s) => dispatch(setEngineMax(s)),
    setChassis: (s) => dispatch(setChassis(s)),
    changeAuctionNumber: (s) => dispatch(changeAuctionNumber(s.target.value)),

    toggleGradeStar: () => dispatch(toggleGradeStar()),
    toggleGrade2: () => dispatch(toggleGrade2()),
    toggleGradeThree: () => dispatch(toggleGradeThree()),
    toggleGradeThreeFive: () => dispatch(toggleGradeThreeFive()),
    toggleGradeFour: () => dispatch(toggleGradeFour()),
    toggleGradeFourFive: () => dispatch(toggleGradeFourFive()),
    toggleGradeFive: () => dispatch(toggleGradeFive()),
    toggleGradeR: () => dispatch(toggleGradeR()),
    setKeyword: (ev) => dispatch(setKeyword(ev.target.value)),
    setDrivetrain: (ev) => dispatch(setDrivetrain(ev.target.value))
});

export default connect(basicMapStateToProps, basicMapDispatchToProps)(Advanced);
