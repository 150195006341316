import React, { Component } from "react";
import { connect } from "react-redux";

import { selectGears } from "../actions/search_actions";

import {
    FormGroup,
    FormControl,
    FormLabel
} from "react-bootstrap";
import { settings } from "../settings";

class AvtoMakes extends Component {
    constructor (props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.selector = "";
    }

    handleChange () {
        const selected = [];
        for (let i = 0; i < this.selector.length; i++) {
            if (this.selector[i].selected) { selected.push(this.selector[i].value); }
        }
        this.props.selectGears(selected);
    }

    componentWillUnmount () {
    // clear this part of the search
        this.props.selectGears(settings.gear_placeholder);
    }

    render () {
    // only render if manual is selected
        if (this.props.trans !== "1") // avto contant for mt
        { return null; }

        return (
            <FormGroup controlId="formControlsSelect">
                <FormLabel>Number of gears:</FormLabel>
                <FormControl as="select" multiple placeholder="select" onChange={this.handleChange} ref={ref => { this.selector = ref; }} >
                    <option key="0" value="">{settings.gear_placeholder}</option>
                    <option key="3" value="3">3</option>
                    <option key="4" value="4">4</option>
                    <option key="5" value="5">5</option>
                    <option key="6" value="6">6</option>
                    <option key="7" value="7">7</option>

                </FormControl>
            </FormGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        trans: state.search.trans
    };
};

const mapDispatchToProps = dispatch => ({
    selectGears: (g) => dispatch(selectGears(g))
});

export default connect(mapStateToProps, mapDispatchToProps)(AvtoMakes);
